import React, {Component} from "react";
import {Link} from "react-router-dom";

class TermsOfSales extends Component {

    render() {
        return (
            <div className="terms">
                <div className="container">
                    <ol className="breadcrumb">
                        <li><a href="/">Home > </a></li>
                        <li className="active">Terms and Conditions</li>
                    </ol>
                    <section className="page-title center">
                        <h1 className="uppercase">Terms and Conditions</h1>
                    </section>

                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>1. WebSafe 365 Introduction</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b>These Terms and Conditions applies to the users of websafe365.com website,
                                        WebSafe 365 Mobile app (Android/iOS) and the WebSafe 365 browser extension.
                                        By accessing this website,our mobile apps, our browser extensions we assume you
                                        accept these terms and conditions
                                        and our <Link to="/privacy-policy">privacy policy</Link> in full.</b>

                                    </p>
                                    <p>Do not continue to use WebSafe365 products if you do not accept all of the
                                        terms and conditions, privacy and cookie policy.</p>
                                    <p>The following terminology applies to these Terms and Conditions, Privacy
                                        Statement and Disclaimer Notice and any or all Agreements:</p>
                                    <p><b>'WebSafe 365' is a trading name for Innovotech Solutions Ltd, a company
                                        registered in England & Wales (company number: 10641546) . Our registered
                                        office address is at Kemp House, 160 City Road, London, England, EC1V
                                        2NX, United Kingdom</b>.</p>
                                    <p>"WebSafe 365", "The Company", “Ourselves”, “We”, “Our” and "Us", refers to
                                        our company, <b>Innovotech Solutions Ltd.</b></p>
                                    <p>"websafe365.com website", "WebSafe 365 Mobile app (Android/iOS)", “WebSafe 365
                                        browser extension”, collective referred as "Our Products".</p>
                                    <p>"Parent", "User", "Client", "Customer", “You” and “Your” refers to you, the
                                        person
                                        accessing "Our Products" and accepting these terms and conditions and our
                                        privacy policy.</p>
                                    <p>“Children”, “Kid” refers to a children who will be using “WebSafe 365 browser
                                        extension” installed desktop web browser after initial setup.</p>
                                    <p>“Website access request” refers to a mobile app notification you will receive
                                        after a children clicking "Request Access" button on their desktop web
                                        browser.</p>
                                    <p>“Web browser”, "Compatible browser" refers to a compatible desktop web browser
                                        installed on children's computer.</p>

                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>2. Our Service</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b>WebSafe 365 offers parent to supervise and
                                        monitor children's web usage on a compatible web browser installed on a
                                        children's desktop computer.</b></p>
                                    <p>Our currently compatible web browsers are Google chrome desktop browsers (version
                                        88.0 onwards)
                                        running on Microsoft Windows, Mac OS and Chrome OS.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>3. How It Works?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>To setup WebSafe 365 successfully, you must follow our <Link to="/setup"> easy <u>setup guide</u> described here</Link>.</p>
                                    <p><b>How It really works?</b></p>
                                    <div className="list-group-item">
                                        <ul className="numbered">
                                            <li>After successful setup of WebSafe 365 on children's desktop web browser
                                                & parents mobile app, all websites accesses on WebSafe 365 extension
                                                installed web browser will be blocked by default.
                                            </li>
                                            <li>Browser will then display a screen which allows children to request
                                                access to the attempted website from their parent.
                                            </li>
                                            <li>If children clicks "request access" button, a mobile app notification
                                                will be sent to parent's WebSafe 365 mobile app.
                                            </li>
                                            <li>Parent's mobile will display a mobile push notification and upon
                                                clicking on the notification, request will be shown on WebSafe 365
                                                mobile app.
                                            </li>
                                            <li>On parent's WebSafe 365 mobile app will then display an options to
                                                accept/reject the children's website access request.
                                            </li>
                                            <li>Parent can choose options to accept the children's website access
                                                request permanently or allow the access for next 15 minutes, 30 minutes,
                                                1 hour up to 3 months.
                                            </li>
                                            <li>If Parent chooses to allow request temporarily, children's web browser
                                                will block that website again after chosen period ends.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>4. FREE 7 Day trial period</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b>We offer free no obligation 7 days trial to try out all our products.</b></p>
                                    <p>Trial start on the day you fully setup WebSafe 365, not the day you download our
                                        mobile app or our browser extension, therefore it should give you full 14 days
                                        access to try out our all features.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>5. Single Price Guarantee</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b>We guarantee that you pay only a single upgrade price to continue using
                                        WebSafe 365 for a 12 months period (upgrade starts after 14 days FREE trial
                                        period).</b></p>
                                    <p>If you decided to purchase an upgrade during 14 day trial period, you will be
                                        eligible to upgrade WebSafe 365 at a discount period.</p>
                                    <p>After 12 months period ends, WebSafe 365 mobile app will notify you to renew your
                                        annual WebSafe 365 subscription. However as of today, we do not auto
                                        charge/renew our customers.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>6. Payment</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b> Our website uses secure SSL/ HTTPS communication for all interactions
                                        between your browser and our servers.</b></p>
                                    <p> All payments are handled securely by our payment service provider, <b>Stripe
                                        Inc</b> (stripe.com).
                                        Stripe is major payment provider in the United States and approved by the
                                        Financial Conduct Authority (FCA) in the UK.</p>
                                    <p> All payments are final and includes any taxes at the prevailing rate.</p>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>6. Cancellation & Refund Policy</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>WebSafe 365 offer 7 days trial period to try out all our features.
                                    </p>
                                    <p>If you are unhappy with WebSafe 365 during 7 days trial period, you can simply
                                        delete our Mobile app and remove our browser extension from kids computer.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>7. Your Personal Data and Privacy</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <ul className="bulleted">
                                        <li> We collect website access request data
                                            when a children clicks "request access" button to request permissions from
                                            you.
                                        </li>
                                        <li> We do <u>NOT</u> collect any personally identifiable data of the children
                                            on kids desktop computer (such as their name, email, age, phone number).
                                        </li>
                                        <li> We do not share your contact information with 3rd parties. However, for
                                            the purpose of payment processing, we share your personal information
                                            with our payment service provider (<b>Stripe.com</b>).
                                        </li>
                                    </ul>
                                    <p><b>Please read our
                                        <Link to="/privacy-policy"> Privacy Policy </Link> in full for further details.</b></p>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>8. Limitations</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <ul className="bulleted">
                                        <li>WebSafe 365 is not a anti-virus guard or a security product.</li>
                                        <li>In accordance with Google's developer terms and conditions, we cannot
                                            restrict chrome desktop user uninstalling the extensions who has the right
                                            privileges to do so.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>9. Contacting us</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b> You can contact us via info@websafe365.com</b></p>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </div>
        );
    };


}

export default TermsOfSales;
