import React, {Component} from "react";
import {Link} from "react-router-dom";

class PrivacyPolicy extends Component {

    render() {
        return (
            <div className="terms">
                <div className="container">
                    <ol className="breadcrumb">
                        <li><a href="/">Home > </a></li>
                        <li className="active">Privacy Policy</li>
                    </ol>

                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>1. WebSafe 365 - Privacy Notice</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>This policy will explain you how we manage your personal data, your privacy
                                        rights and how the law protects you.</p>
                                    <p>This website is not intended for anyone below 18 years old. We do not
                                        intentionally collect personal data relating to children.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>2. Who we are</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b>'WebSafe 365' is a trading name for Innovotech Solutions Ltd, a company
                                        registered in England & Wales (company number: 10641546) . Our registered office
                                        address is at Kemp House, 160 City Road, London, England, EC1V 2NX</b>.</p>
                                    <p>This website is operated and controlled by <b>Innovotech Solutions Ltd</b>, who
                                        is the data controller and responsible for your personal data (referred to as
                                        ‘WebSafe 365’, “we”, “us” or “our” in this privacy notice).</p>
                                    <p>If you have any questions about this privacy notice, including any requests to
                                        exercise your legal rights, please contact us via below email:</p>
                                    <p><b>Email address: info@websafe365.com</b></p>
                                </div>terms-and-conditions

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>3. What data do we collect?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b>We may collect, use, store and transfer below types of personal data about
                                        you and your children who use WebSafe 365 products:</b></p>
                                    <div className="list-group-item">
                                        <p><b>On Parents mobile app and safe365.com</b></p>
                                        <ul className="bulleted">
                                            <li>Your identity data – full name</li>
                                            <li>Your contact data – email address, telephone numbers, billing address,
                                                delivery address
                                            </li>
                                            <li>Technical data – IP address, browser types, time zones, locations,
                                                operating
                                                systems, mobile OS version.
                                            </li>
                                            <li>Profile data – your email address, your purchase history</li>
                                            <li>Usage data - including information about how you use our website,
                                                products
                                                and services.
                                            </li>
                                            <li>Marketing Data – your preferences in receiving marketing updates from
                                                us,
                                                our third parties and your communication preferences.
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="list-group-item">
                                        <p><b>On Children's WebSafe 365 extension installed desktop browser.</b></p>
                                        <ul className="bulleted">
                                            <li>Website that requires access for the first time - full website
                                                address.
                                            </li>
                                            <li>Technical data – IP address, browser types, time zones, locations,
                                                operating
                                                systems.
                                            </li>
                                            <li>NO PERSONALLY IDENTIFIABLE DATA WILL BE RECORDED ABOUT THE CHILDREN.
                                            </li>
                                        </ul>
                                    </div>

                                    <p> In addition to above, we collect, use and share aggregated data such as
                                        demographic data for any purpose. Aggregated data may be derived from your
                                        personal data or from usage data but is not considered personal data as they do
                                        not directly or indirectly reveal your identity. However, if we use data that it
                                        will directly or indirectly reveal your identity, we treat your data and will be
                                        used accordance to this privacy policy.</p>
                                    <p> We do not share your name, contact or address information with any other 3rd
                                        party other than our Payment Service Provider, Stripe, Inc.</p>
                                    <p> We do not collect your personal data which will reveal your race, ethnicity,
                                        religious, sexual orientation, political opinions, your information on health,
                                        biometric data, etc…</p>

                                    <p><b>If you fail to provide required personal data:</b></p>
                                    <p> Where we need to collect personal data by law, or under the terms of a contract
                                        and if you fail to provide the data when requested, we may not be able to
                                        perform the contract we have or we are trying to enter into with you. In this
                                        case, we may have to cancel the service providing to you but we will notify you
                                        in such event.</p>


                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>4. How we collect your personal data</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <h3>We use different methods to collect data from and about you including
                                        through:</h3>
                                    <p><b> Direct interactions: </b> You may give us your Identity, Contact and
                                        Financial Data by filling in forms or by corresponding with us by post, phone,
                                        email or otherwise.
                                    </p>
                                    <div className="list-group-item">
                                        <p><b>This includes personal data you provide when you:</b></p>
                                        <ul className="bulleted">
                                            <li>place an order for our products or services;</li>
                                            <li>create an account on our website or via our WebSafe 365 mobile app;</li>
                                            <li>subscribe to our service or publications;</li>
                                            <li>request marketing to be sent to you;</li>
                                            <li>enter a competition, promotion or survey;</li>
                                            <li>or give us some feedback.</li>
                                        </ul>
                                    </div>

                                    <p><b>Automated technologies or interactions: </b> As you interact with our website,
                                        we may automatically collect Technical Data about your equipment, browsing
                                        actions and patterns. We collect this personal data by using cookies, server
                                        logs and other similar technologies. We may also receive Technical Data about
                                        you if you visit other websites employing our cookies. Please see our
                                        <Link
                                            to="/cookies-and-personal-data">Cookie policy</Link> for further details.
                                    </p>
                                    <p><b>Third parties or publicly available sources:</b> We may receive personal
                                        Technical Data about you from various third parties and public sources as set
                                        out below:</p>
                                    <div className="list-group-item">
                                        <ul className="bulleted">
                                            <li>analytics providers such as Google based outside the EU;</li>
                                            <li>advertising networks such as Google AdWords;</li>
                                            <li>advertising networks such as Facebook Ads;</li>
                                            <li>and search information providers such as Google based inside or outside
                                                the
                                                EU.
                                            </li>
                                            <li>enter a competition, promotion or survey;</li>
                                            <li>or give us some feedback on 3rd party websites.</li>
                                        </ul>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>5. How we use your personal data?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">

                                    <div className="list-group-item">
                                        <ul className="bulleted">
                                            <p><b> We will use your data:</b></p>
                                            <li>Where we need to perform the contract we are about to enter into or have
                                                entered into with you.
                                            </li>
                                            <li>Where it is necessary for our legitimate interests (or those of a third
                                                party) and your interests and fundamental rights do not override those
                                                interests.
                                            </li>
                                            <li>Where we need to comply with a legal or regulatory obligation.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>6. Disclosures of Your Personal Data with other parties</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b> We may share your personal information with other parties
                                        involving and facilitating our service:</b></p>
                                    <ul>
                                        <li><b>Google Inc & Amazon Inc:</b> We use Google and Amazon online and physical
                                            infrastructure products to facilitate and store your personal data and
                                            photos.
                                        </li>
                                        <li><b>Google Analytics and other 3rd party analytic tools:</b> We may share
                                            your information with 3rd web analytics companies.
                                        </li>
                                        <li><b>Stripe Inc:</b> Stripe, Inc (stripe.com) is our payment service provider.
                                            Stripe is authorised by the Financial Conduct Authority (FCA) in the UK.
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>7. Data retention</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>We will only retain your personal data for as long as necessary to fulfil the
                                        purposes we collected it for, including for the purposes of satisfying any
                                        legal, accounting, or reporting requirements.</p>
                                    <p>By law we have to keep basic information about our customers (including Contact,
                                        Identity, Financial and Transaction Data) for seven years after they cease being
                                        customers for tax and legal purposes. We audit our data on a quarterly basis to
                                        ascertain any data which has reached the retention period and delete such data
                                        insofar as technically possible.
                                    </p>
                                    <p>You can ask us to delete your data anytime. But in some circumstances we may
                                        anonymize your personal data ( anonymize data so that it is no longer
                                        associated with you) for analytical purposes in which case we may use this
                                        information without further notice to you.
                                        If you wants us to delete your data please send an email to  info@websafe365.com.
                                    </p>

                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>8. Contacting us</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b> You can contact us via info@websafe365.com</b></p>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </div>
        );
    };


}

export default PrivacyPolicy;
