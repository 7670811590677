import React, {Component} from "react";

class SpinRaisePrivacyPolicy extends Component {

    render() {
        return (
            <div className="terms">
                <div className="container">
                    <ol className="breadcrumb">
                        <li><a href="/">Home > </a></li>
                        <li className="active">Privacy Policy</li>
                    </ol>

                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>1. Face Refine with AI - Privacy Notice</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>This policy will explain you how we manage your personal data, your privacy
                                        rights and how the law protects you.</p>
                                    <p>We do not
                                        intentionally collect personal data relating to children.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>2. Who we are</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b>'Face Refine' is a trading name for Innovotech Solutions Ltd, a company
                                        registered in England & Wales (company number: 10641546) . Our registered office
                                        address is at 128 City Road, London, England, EC1V 2NX</b>.</p>
                                    <p>This website is operated and controlled by <b>Innovotech Solutions Ltd</b>, who
                                        is the data controller and responsible for your personal data (referred to as
                                        ‘Face Refine’, “we”, “us” or “our” in this privacy notice).</p>
                                    <p>If you have any questions about this privacy notice, including any requests to
                                        exercise your legal rights, please contact us via below email:</p>
                                    <p><b>Email address: info@websafe365.com</b></p>
                                </div>terms-and-conditions

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>3. What data do we collect?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b>We do not collect any personal data on this app.</b></p>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>6. Disclosures of Your Personal Data with other parties</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b> we do not share any personal data with other parties</b></p>
                                    <ul>
                                        <li>We use Google and Amazon online and physical
                                            infrastructure products to facilitate and store your Face Refine scoring information.
                                        </li>
                                        <li>We use Google Ads to show ads on our mobile app. However no personal data are not shared with our Ads providers.
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>8. Contacting us</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b> You can contact us via info@websafe365.com</b></p>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </div>
        );
    };


}

export default SpinRaisePrivacyPolicy;
