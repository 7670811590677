import React from "react";
import homebg from "../img/hero/hero-bg-4.png";
import appleStoreBadge from "../img/hero/apple_store_badge.svg";
import googleWebStoreBadge from "../img/hero/google-web-store-badge.png";
import microsoftLogo from "../img/hero/microsoft-logo.jpg";
import shape2 from "../img/hero/shape-2.png";
import shape3 from "../img/hero/shape-3.png";
import mobile1 from "../img/screen/websafe-365-main-app-ui.png";

const Hero = () => {
    return (
        <>
            <div
                id="apork-hero"
                className="apork-hero-area hero-bg position-relative"
                style={{background: `url('${homebg}')`}}
            >
                <div className="hero-style-shape">
                    <img className="style-shape shape-2" src={shape2} alt="imgshape"/>
                    <img
                        className="style-shape aporkrot shape-3"
                        src={shape3}
                        alt="imgshape"
                    />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-8 col-md-12">
                            <div className="hero-content">
                                <h2>
                                    Control your children's web browsing using WebSafe 365.
                                </h2>
                                <p>
                                </p>
                                <p>
                                    Allow or disallow any website your kids trying to visit on their desktop computer.
                                </p>
                                <p>
                                    Set time limits on how long kid should access a website.
                                </p>
                                <p>
                                    View the website on your mobile, before your kid view it.
                                </p>
                                <div className="hero-btn">
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-4">
                                            <a href='https://play.google.com/store/apps/details?id=com.innovotech.websafe365&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                                <div className="play-store-badge">
                                                    <img alt='Get it on Google Play'
                                                         src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-xs-6 col-sm-4">
                                            <a href='https://apps.apple.com/us/app/websafe-365/id1589704427'>
                                                <div className="app-store-badge">
                                                    <img alt='Get it on Apps Store'
                                                         src={appleStoreBadge}/>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-4">
                                            <a href='https://chrome.google.com/webstore/detail/websafe365/caalgailaopfobcgmhcglflekfefpcdn'>
                                                <div className="web-store-badge">
                                                    <img alt='Get it on Google Web Store'
                                                         src={googleWebStoreBadge}/>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-xs-6 col-sm-4">
                                            <a href='https://microsoftedge.microsoft.com/addons/detail/websafe365/fkpmcfdhmoecfjdmmoddmclhdjacojmc'>
                                                <div className="microsoft-store-badge">
                                                    <img alt='Get Microsoft Edge Add-ons'
                                                         src={microsoftLogo}/>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 offset-xl-1 col-lg-4 wow fadeInRight">
                            <div className="hero-mobile-img">
                                <img src={mobile1} alt="imgmobile"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
