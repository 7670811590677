import React from "react";

const About = () => {
  return (
    <>
      <div id="apork-about-area" className="apork-about-area mt-230">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 mx-auto">
              <div className="section-title text-center mb-80">
                <h1 className="text-highlighter">"Help kids to stay focus while on the web!"</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
