import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Features from "../components/Features";
import HowToSetup from "../components/HowToSetup";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Pricing from "../components/Pricing";
import Video from "../components/Video";

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <Hero />
        <About />
        <Video />
        <Features />
        <HowToSetup />
        <Pricing />
        <Footer />
      </main>
    </>
  );
};

export default Home;
